import { useEffect, useRef } from 'react'

const Redirect = () => {
	const refLink = useRef()

	useEffect(() => {
		refLink?.current && refLink.current.click()
	}, [])

	return (
		<div className='flex w-full min-h-screen justify-center items-center'>
			<a
				href='https://apps.apple.com/app/idol-world-dance-with-idol/id6444822913'
				ref={refLink}
			>
				Redirecting...
			</a>
		</div>
	)
}

export default Redirect
