import activityApi from 'apis/acivity'
import CopyButton from 'components/Button/CopyButton'
import { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

const View = () => {
	const [searchParams] = useSearchParams()

	const [data, setData] = useState({
		inviteCode: '',
		inviteLink: '',
		maxReward: ''
	})

	const [error, setError] = useState('')
	const [loading, setLoading] = useState(true)

	const getData = useCallback(async () => {
		setLoading(true)
		try {
			const result = await activityApi.userShare({
				campaign: searchParams.get('campaign'),
				user_id: searchParams.get('user_id')
			})
			if (result.code === 0) {
				setData(result.data)
				setError('')
				return
			}
			throw result.message
		} catch (error) {
			console.error(error)
			setError(true)
		} finally {
			setTimeout(() => {
				setLoading(false)
			}, 2000)
		}
	}, [searchParams])

	useEffect(() => {
		getData()
	}, [getData])

	return (
		<main className='min-h-screen app p-3 lg:p-8 flex items-center justify-between flex-col'>
			<div className='h-12'></div>
			<div className='bg-white w-full max-w-3xl rounded-lg p-5 pb-10 lg:p-10 text-center relative border-[3px] border-violet-400 shadow-lg z-20 space-y-5'>
				<div className='space-y-5'>
					<p className='text-xl lg:text-2xl font-semibold'>
						Enter my invitation code and claim rewards up to
					</p>
					<p className='text-3xl lg:text-4xl font-bold text-yellow-500'>
						{data.maxReward}
					</p>
				</div>
				<div className='w-full'>
					<ul className='text-left w-full space-y-4'>
						<li className=''>
							<div className='flex w-full gap-4'>
								<div className='w-8 h-8 min-w-[2rem] bg-violet-500 text-violet-50 rounded-full flex items-center justify-center'>
									1
								</div>
								<p className='h-8 flex items-center'>Open the game</p>
							</div>
						</li>
						<li className='w-full'>
							<div className='flex w-full gap-4'>
								<div className='w-8 h-8 min-w-[2rem] bg-violet-500 text-violet-50 rounded-full flex items-center justify-center'>
									2
								</div>
								<div className='w-full space-y-2'>
									<p className='h-8 flex items-center'>
										Enter my invitation code
									</p>
									<div className='w-full'>
										<form className='w-full'>
											<div className='relative'>
												<input
													readOnly={true}
													className={`w-full z-[1] bg-violet-100 rounded-md py-2.5 pl-4 pr-24 text-sm lg:text-base
                          border-none shadow-none outline-none text-violet-700 ring ring-violet-200`}
													placeholder={'Waiting code...'}
													value={data.inviteCode}
													onChange={() => {}}
												/>
												<div className='absolute top-1/2 -translate-y-1/2 right-1.5 z-[2] flex items-center'>
													<CopyButton value={data.inviteCode} />
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</li>
						<li>
							<div className='flex w-full gap-4'>
								<div className='w-8 h-8 min-w-[2rem] bg-violet-500 text-violet-50 rounded-full flex items-center justify-center'>
									3
								</div>
								<p className='h-8 flex items-center'>Claim the reward</p>
							</div>
						</li>
					</ul>
				</div>

				<div className='absolute h-28 w-full left-0 -top-[106px] z-10 !mt-0'>
					<img
						src='https://lf16-tiktok-web.ibytedtos.com/obj/tiktok-web/falcon/armor_access/dist/web/images/image-main-bg.f365b92d.png'
						alt='ig-tiktok'
						className='h-full object-contain w-full'
					/>
				</div>
				<div className='absolute w-full h-5 top-0 left-0 bg-white z-20 rounded-xl !mt-0'></div>
				{error && !loading ? (
					<div className='absolute w-full h-full z-30 flex items-center justify-center top-0 left-0 bg-white rounded-2xl !mt-0'>
						Rất tiếc. Chúng tôi không tìm thấy mã mời này!
					</div>
				) : null}

				{loading ? (
					<div className='absolute w-full h-full z-30 flex items-center justify-center top-0 left-0 bg-white rounded-2xl !mt-0'>
						<span class='loader'></span>
					</div>
				) : null}
			</div>

			<div
				className='h-12 bg-yellow-500 text-white flex items-center px-9 rounded-lg 
				ring ring-yellow-100/40 shadow-lg cursor-pointer hover:scale-125 transition-transform duration-200 ease-in-out will-change-transform select-none'
			>
				{!error && !loading ? (
					<a href={data.inviteLink} target='_blank' rel='noreferrer'>
						Download now
					</a>
				) : (
					<div>Loading...</div>
				)}
			</div>
		</main>
	)
}

export default View
