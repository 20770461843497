const ClipboardIcon = (props) => {
  return (
    <svg viewBox='0 0 20 20' aria-hidden='true' {...props}>
      <path
        strokeWidth='0'
        d='M5.5 13.5v-5a2 2 0 0 1 2-2l.447-.894A2 2 0 0 1 9.737 4.5h.527a2 2 0 0 1 1.789 1.106l.447.894a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-5a2 2 0 0 1-2-2Z'
      />
      <path
        fill='none'
        strokeLinejoin='round'
        d='M12.5 6.5a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-5a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2m5 0-.447-.894a2 2 0 0 0-1.79-1.106h-.527a2 2 0 0 0-1.789 1.106L7.5 6.5m5 0-1 1h-3l-1-1'
      />
    </svg>
  )
}

export default ClipboardIcon
