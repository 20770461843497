import Page404 from 'pages/404'
import Invite from 'pages/Invite'
import Redirect from 'pages/Redirect'
import { Route, Routes } from 'react-router-dom'
import './App.css'

function App() {
	return (
		<Routes>
			<Route path='/referral/:inviteCode' element={<Redirect />} />
			<Route path='/activity/user_share' element={<Invite />} />
			<Route path='*' element={<Page404 />} />
		</Routes>
	)
}

export default App
