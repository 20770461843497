import axiosClient from './http-common'

const activityApi = {
	userShare: params => {
		const url = `/users/campaigns/${params.campaign}/invite-info?userId=${params.user_id}`
		return axiosClient.get(url)
	}
}

export default activityApi
