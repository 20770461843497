const Page404 = () => {
	return (
		<div className='h-screen w-full flex items-center justify-center flex-col'>
			<p className='text-6xl lg:text-9xl font-bold'>404</p> <br />{' '}
			<p className='text-2xl font-medium'>Page not found</p>
		</div>
	)
}

export default Page404
